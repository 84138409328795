import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import useFetchTranslations from "../hooks/useFetchTranslations";
import useFetchProjects from "../hooks/useFetchProjects";

function RecentProjectsSection() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("ProjectsSection");
  const { projects, loadingProjects } = useFetchProjects();

  if (loadingTranslations || loadingProjects) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Row className="mb-5 text-center">
        <Col>
          <h2>{t("recentProjectsTitle")}</h2>
        </Col>
      </Row>
      <Row>
        {projects.slice(0, 3).map((project) => (
          <Col md={4} key={project.id}>
            <Card className="mb-4">
              <Card.Img variant="top" src={`${project.image}`} />
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text>{project.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="mb-5 text-center">
        <Col>
          <Button className="btn btn-primary-custom" as={Link} to="/projects">
            {t("viewMoreProjects")}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default RecentProjectsSection;
