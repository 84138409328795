import { useState, useEffect } from "react";
import api from "../api";
import { useTranslation } from "react-i18next";

export default function useFetchProjects() {
  const { i18n } = useTranslation();
  const [projects, setProjects] = useState([]);
  const [loadingProjects, setLoadingProjects] = useState(true);

  useEffect(() => {
    async function fetchProjects() {
      setLoadingProjects(true);
      try {
        const response = await api.get("projects/", {
          headers: { "Accept-Language": i18n.language },
        });
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      } finally {
        setLoadingProjects(false);
      }
    }

    fetchProjects();
  }, [i18n.language]);

  return { projects, loadingProjects };
}
