import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import useFetchTranslations from "../hooks/useFetchTranslations";
import useFetchServices from "../hooks/useFetchServices";

function ServicesSection() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("ServicesSection");
  const { services, loadingServices } = useFetchServices();

  // Mostrar indicador de carga si las traducciones o los servicios están cargando
  if (loadingTranslations || loadingServices) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Row className="mb-5 text-center">
        <Col>
          <h2>{t("ourServices")}</h2>
        </Col>
      </Row>
      <Row>
        {services.map((service) => (
          <Col md={4} key={service.id}>
            <Card className="mb-4 text-center">
              {service.icon && <Card.Img variant="top" src={service.icon} />}
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="mb-5 text-center">
        <Col>
          <Button className="btn btn-primary-custom" as={Link} to="/services">
            {t("viewAllServices")}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default ServicesSection;
