import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.svg";
import useFetchTranslations from "../hooks/useFetchTranslations";
import useFetchVisibilityRules from "../hooks/useFetchVisibilityRules";
import LoadingSpinner from "./LoadingSpinner";

function NavigationBar() {
  const { i18n, t } = useTranslation();
  const loadingTranslations = useFetchTranslations("NavigationBar");
  const { visibilityRules, loading: loadingVisibility } =
    useFetchVisibilityRules();

  // Configurar el idioma predeterminado a español al cargar el componente
  useEffect(() => {
    i18n.changeLanguage("es");
  }, [i18n]);

  if (loadingTranslations || loadingVisibility) return <LoadingSpinner />;

  return (
    <Navbar className="navbar-custom" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={logo}
            alt="Wayak TI"
            className="d-inline-block align-top logo-image"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {visibilityRules.ServicesSection && (
              <Nav.Link as={Link} to="/services">
                {t("navServices")}
              </Nav.Link>
            )}
            {visibilityRules.RecentProjectsSection && (
              <Nav.Link as={Link} to="/projects">
                {t("navPortfolio")}
              </Nav.Link>
            )}
            {visibilityRules.LatestPostsSection && (
              <Nav.Link as={Link} to="/blog">
                {t("navBlog")}
              </Nav.Link>
            )}
            {visibilityRules.contact && (
              <Nav.Link as={Link} to="/contact">
                {t("navContact")}
              </Nav.Link>
            )}
            {visibilityRules.about && (
              <Nav.Link as={Link} to="/about">
                {t("navAbout")}
              </Nav.Link>
            )}
            {visibilityRules.languageMenu && ( // Mostrar el botón de idiomas si la regla es verdadera
              <Dropdown align="end">
                <Dropdown.Toggle
                  variant="outline-secondary"
                  id="language-dropdown"
                >
                  {t("navLanguage")}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => i18n.changeLanguage("es")}>
                    Español
                  </Dropdown.Item>
                  <Dropdown.Item onClick={() => i18n.changeLanguage("en")}>
                    English
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavigationBar;
