import { useState, useEffect } from 'react';
import api from '../api';
import { useTranslation } from 'react-i18next';

export default function useFetchServices() {
  const { i18n } = useTranslation();
  const [services, setServices] = useState([]);
  const [loadingServices, setLoadingServices] = useState(true);

  useEffect(() => {
    async function fetchServices() {
      setLoadingServices(true);
      try {
        const response = await api.get('services/', {
          headers: { 'Accept-Language': i18n.language },
        });
        setServices(response.data);
      } catch (error) {
        console.error('Error fetching services:', error);
      } finally {
        setLoadingServices(false);
      }
    }

    fetchServices();
  }, [i18n.language]);

  return { services, loadingServices };
}