// LogoGrid.jsx
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';

// Importar las rutas de los logos
import htmlLogo from '../assets/logos/html5_logo.svg';
import cssLogo from '../assets/logos/css3_logo.svg';
import jsLogo from '../assets/logos/js_logo.svg';
import reactLogo from '../assets/logos/react_logo.svg';
import djangoLogo from '../assets/logos/django_logo.svg';
import javaLogo from '../assets/logos/java_logo.svg';
import phpLogo from '../assets/logos/php_logo.svg';
import typescriptLogo from '../assets/logos/typescript_logo.svg';
import bootstrapLogo from '../assets/logos/bootstrap_logo.svg';
import materialuiLogo from '../assets/logos/materialui_logo.svg';
import laravelLogo from '../assets/logos/laravel_logo.svg';
import wordpressLogo from '../assets/logos/wordpress_logo.svg';
import androidLogo from '../assets/logos/android_logo.svg';
import iosLogo from '../assets/logos/ios_logo.svg';
import springbootLogo from '../assets/logos/springboot_logo.svg';
import pythonLogo from '../assets/logos/python_logo.svg';

function LogoGrid() {
  const { t } = useTranslation(); // Hook de traducción

  return (
    <>
      <Row className="my-4 text-center">
        <Col>
          <h2>{t("languagesAndFrameworksTitle")}</h2>
        </Col>
      </Row>
      <Row className="logos-row text-center">
        {[htmlLogo, cssLogo, jsLogo, typescriptLogo, phpLogo, javaLogo, pythonLogo, reactLogo, bootstrapLogo, materialuiLogo, laravelLogo, wordpressLogo, djangoLogo, springbootLogo, androidLogo, iosLogo].map((logo, index) => (
          <Col xs={6} sm={4} md={2} key={index}>
            <ReactSVG src={logo} className="logo-svg" />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default LogoGrid;
