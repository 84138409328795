import React from "react";
import { Form, Button, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useFetchTranslations from "../hooks/useFetchTranslations";
import useSubmitForm from "../hooks/useSubmitForm";
import LoadingSpinner from "./LoadingSpinner";

function ContactForm() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("ContactForm");

  const { formData, submitted, handleChange, handleSubmit } = useSubmitForm(
    "contacts/", // URL de la API
    { name: "", email: "", message: "" } // Datos iniciales del formulario
  );

  if (loadingTranslations) {
    return <LoadingSpinner />;
  }

  return (
    <Container className="mt-5">
      <h2>{t("contactTitle")}</h2>
      {submitted ? (
        <p>{t("thankYouMessage")}</p>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>{t("nameLabel")}</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder={t("namePlaceholder")}
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>{t("emailLabel")}</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder={t("emailPlaceholder")}
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formMessage">
            <Form.Label>{t("messageLabel")}</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              rows={3}
              placeholder={t("messagePlaceholder")}
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Button type="submit" className="mt-3 btn btn-primary-custom">
            {t("submitButton")}
          </Button>
        </Form>
      )}
    </Container>
  );
}

export default ContactForm;
