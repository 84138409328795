import React from 'react';
import logo from '../assets/images/logo.svg'; // Asegúrate de que la ruta del logo sea correcta
import '../scss/_logoSpinner.scss'; // Archivo CSS para los estilos

function LogoSpinner() {
    return (
      <div className="spinner-overlay">
        <div className="spinner-container">
          <img src={logo} alt="Logo" className="spinner-logo" />
          <div className="spinner-circle green"></div>
          <div className="spinner-circle black"></div>
        </div>
      </div>
    );
  }
  
  export default LogoSpinner;
