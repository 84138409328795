import React from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BASE_URL } from "../api";
import useFetchBlogPosts from "../hooks/useFetchBlogPosts";
import useFetchTranslations from "../hooks/useFetchTranslations";
import LoadingSpinner from "./LoadingSpinner";

function LatestPostsSection() {
  const { t, i18n } = useTranslation();

  // Usar hooks para cargar traducciones y publicaciones del blog
  const loadingTranslations = useFetchTranslations("LatestPostsSection");
  const { blogPosts, loading: loadingPosts } = useFetchBlogPosts(i18n.language);

  // Mostrar spinner mientras se cargan traducciones o publicaciones
  if (loadingTranslations || loadingPosts) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Row className="mb-5 text-center">
        <Col>
          <h2>{t("latestPostsTitle")}</h2>
        </Col>
      </Row>
      <Row>
        {blogPosts.slice(0, 3).map((post) => (
          <Col md={4} key={post.id}>
            <Card className="mb-4">
              <Card.Img variant="top" src={`${BASE_URL}${post.image}`} />
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.content.substring(0, 100)}...</Card.Text>
                <Button variant="link" as={Link} to={`/blog/${post.id}`}>
                  {t("readMore")}
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="mb-5 text-center">
        <Col>
          <Button className="btn btn-primary-custom" as={Link} to="/blog">
            {t("viewAllPosts")}
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default LatestPostsSection;
