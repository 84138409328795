import { useState, useEffect } from "react";
import api from "../api";

const useFetchBlogPost = (id, language) => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchBlogPost() {
      setLoading(true);
      try {
        const response = await api.get(`blogposts/${id}/`, {
          headers: { "Accept-Language": language },
        });
        setPost(response.data);
      } catch (error) {
        console.error("Error fetching blog post:", error);
        setPost(null);
      } finally {
        setLoading(false);
      }
    }

    if (id) fetchBlogPost();
  }, [id, language]);

  return { post, loading };
};

export default useFetchBlogPost;