import React from 'react';
import { Carousel } from 'react-bootstrap';

function Testimonials() {
  return (
    <div className="testimonials my-5">
      <h2 className="text-center mb-4">Testimonios</h2>
      <Carousel>
        <Carousel.Item>
          <blockquote className="blockquote text-center">
            <p className="mb-0">
              "Wayak TI transformó nuestra presencia en línea. ¡Altamente recomendados!"
            </p>
            <footer className="blockquote-footer">
              Juan Pérez, <cite title="Empresa ABC">Empresa ABC</cite>
            </footer>
          </blockquote>
        </Carousel.Item>
        <Carousel.Item>
          <blockquote className="blockquote text-center">
            <p className="mb-0">
              "Su equipo de desarrollo es excepcional. Nos ayudaron a lanzar nuestra app en tiempo récord."
            </p>
            <footer className="blockquote-footer">
              María López, <cite title="Startup XYZ">Startup XYZ</cite>
            </footer>
          </blockquote>
        </Carousel.Item>
      </Carousel>
    </div>
  );
}

export default Testimonials;