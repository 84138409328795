import React from "react";
import { Card, Container, Row, Col, Badge } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import useFetchTranslations from "../hooks/useFetchTranslations";
import useFetchProjects from "../hooks/useFetchProjects";

function Projects() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("ProjectsSection"); // Hook para traducciones
  const { projects, loadingProjects } = useFetchProjects(); // Hook para cargar proyectos

  if (loadingTranslations || loadingProjects) {
    return <LoadingSpinner />;
  }

  return (
    <Container className="mt-5">
      <Row className="mb-5 text-center">
        <Col>
          <h2>{t("projectsTitle")}</h2>
        </Col>
      </Row>
      <Row>
        {projects.map((project) => (
          <Col md={4} key={project.id}>
            <Card className="mb-4">
              {project.image && (
                <Card.Img
                  variant="top"
                  src={project.image}
                  alt={project.title}
                />
              )}
              <Card.Body>
                <Card.Title>{project.title}</Card.Title>
                <Card.Text>{project.description}</Card.Text>
                <div>
                  {project.technologies.split(",").map((tech, index) => (
                    <Badge bg="primary" className="me-1" key={index}>
                      {tech.trim()}
                    </Badge>
                  ))}
                </div>
              </Card.Body>
              {project.url && (
                <Card.Footer>
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("viewProject")}
                  </a>
                </Card.Footer>
              )}
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
export default Projects;
