import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import useFetchTranslations from "../hooks/useFetchTranslations";
import useFetchServices from "../hooks/useFetchServices";

function Services() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("Services");
  const { services, loadingServices } = useFetchServices();

  if (loadingTranslations || loadingServices) {
    return <LoadingSpinner />;
  }

  return (
    <Container className="mt-5">
      <Row className="mb-5 text-center">
        <Col>
          <h2>{t("ourServices")}</h2>
        </Col>
      </Row>
      <Row>
        {services.map((service) => (
          <Col md={4} key={service.id}>
            <Card className="mb-4">
              {service.icon && (
                <Card.Img
                  variant="top"
                  src={service.icon}
                  alt={service.title}
                />
              )}
              <Card.Body>
                <Card.Title>{service.title}</Card.Title>
                <Card.Text>{service.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}
export default Services;
