import React from 'react';
import Services from '../components/Services';

function ServicesPage() {
  return (
    <div>
      <Services />
    </div>
  );
}

export default ServicesPage;