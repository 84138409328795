import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE || "es"; // Idioma por defecto si no se define en .env

async function loadTranslations(language, category) {
  try {
    const response = await fetch(`${BASE_URL}/api/translations/?language=${language}&category=${category}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error loading translations:", error);
    return {};
  }
}

i18n.use(initReactI18next).init({
  lng: DEFAULT_LANGUAGE,  // Usa el idioma predeterminado desde la variable de entorno
  fallbackLng: "es",
  interpolation: {
    escapeValue: false,  // react already safes from xss
  },
  resources: {},  // Aquí puedes agregar recursos si es necesario
});

export { loadTranslations };
export default i18n;