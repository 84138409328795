import React from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "./LoadingSpinner";
import useFetchBlogPosts from "../hooks/useFetchBlogPosts";
import useFetchTranslations from "../hooks/useFetchTranslations";

function Blog() {
  const { t, i18n } = useTranslation();

  const loadingTranslations = useFetchTranslations("BlogSection");
  const { blogPosts, loading: loadingPosts } = useFetchBlogPosts(i18n.language);

  if (loadingTranslations || loadingPosts) {
    return <LoadingSpinner />;
  }

  return (
    <Container className="mt-5">
      <Row className="mb-5 text-center">
        <Col>
          <h2>{t("latestPostsTitle")}</h2>
        </Col>
      </Row>
      <Row>
        {Array.isArray(blogPosts) &&
          blogPosts.map((post) => (
            <Col md={6} key={post.id}>
              <Card className="mb-4">
                {post.image && (
                  <Card.Img variant="top" src={post.image} alt={post.title} />
                )}
                <Card.Body>
                  <Card.Title>{post.title}</Card.Title>
                  <Card.Text>{post.content.substring(0, 100)}...</Card.Text>
                  <Link to={`/blog/${post.id}`}>{t("readMore")}</Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
      </Row>
    </Container>
  );
}

export default Blog;
