import { useState, useEffect } from 'react';
import api from '../api';

const useFetchVisibilityRules = () => {
  const [visibilityRules, setVisibilityRules] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchVisibilityRules() {
      try {
        const response = await api.get('visibility-rules/');
        const rules = response.data.reduce((acc, rule) => {
          acc[rule.section_name] = rule.is_visible;
          return acc;
        }, {});
        setVisibilityRules(rules);
      } catch (error) {
        console.error('Error fetching visibility rules:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchVisibilityRules();
  }, []);

  return { visibilityRules, loading };
};

export default useFetchVisibilityRules;