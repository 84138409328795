import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchTranslations from "../hooks/useFetchTranslations"; // Importa el hook personalizado
import imagenInicio from "../assets/images/imagen_inicio.webp";
import LoadingSpinner from "./LoadingSpinner";

function WelcomeSection() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("WelcomeSection");

  // Si las traducciones están cargando, muestra el componente de carga
  if (loadingTranslations) {
    return <LoadingSpinner />;
  }

  return (
    <Row className="align-items-center">
      <Col md={6} className="text-center">
        <h1>{t("welcomeTitle")}</h1>
        <p>{t("welcomeDescription")}</p>
        <Button className="btn btn-primary-custom" as={Link} to="/contact">
          {t("contactUs")}
        </Button>
      </Col>
      <Col md={6}>
        <img src={imagenInicio} alt="Imagen de inicio" className="img-fluid" />
      </Col>
    </Row>
  );
}

export default WelcomeSection;
