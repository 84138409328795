import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useFetchTranslations from "../hooks/useFetchTranslations";
import LoadingSpinner from "./LoadingSpinner";

function CtaSection() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("CtaSection");

  if (loadingTranslations) {
    return <LoadingSpinner />;
  }

  return (
    <div className="cta-section text-center py-5">
      <h2>{t("ctaTitle")}</h2>
      <p>{t("ctaDescription")}</p>
      <Button className="btn btn-secondary-custom" as={Link} to="/contact">
        {t("ctaButtonText")}
      </Button>
    </div>
  );
}

export default CtaSection;
