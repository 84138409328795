import { useState, useEffect } from "react";
import api from "../api";

const useFetchBlogPosts = (language) => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchBlogPosts() {
      setLoading(true);
      try {
        const response = await api.get("blogposts/", {
          headers: { "Accept-Language": language },
        });
        setBlogPosts(Array.isArray(response.data) ? response.data : []);
      } catch (error) {
        console.error("Error fetching blog posts:", error);
        setBlogPosts([]);
      } finally {
        setLoading(false);
      }
    }

    fetchBlogPosts();
  }, [language]);

  return { blogPosts, loading };
};

export default useFetchBlogPosts;
