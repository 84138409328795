import React from 'react';
import { useTranslation } from 'react-i18next';
import useFetchTranslations from '../hooks/useFetchTranslations';
import LoadingSpinner from './LoadingSpinner';

function Footer() {
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations('Footer'); // Utiliza el hook con la sección 'Footer'

  if (loadingTranslations) return <LoadingSpinner />;

  return (
    <footer className="footer mt-5">
      <p>&copy; {new Date().getFullYear()} Wayak TI. {t('footerRightsReserved')}</p>
    </footer>
  );
}

export default Footer;
