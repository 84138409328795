import axios from 'axios';

// Usar la variable de entorno REACT_APP_BASE_URL
export const BASE_URL = process.env.REACT_APP_BASE_URL;

// Crear una instancia de axios con la baseURL
const api = axios.create({
  baseURL: `${BASE_URL}/api/`,
});

// Exportar la instancia de axios como exportación por defecto
export default api;