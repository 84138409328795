import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import LoadingSpinner from "../components/LoadingSpinner";
import LogoGrid from "../components/LogoGrid";
import useFetchTranslations from "../hooks/useFetchTranslations";
import { useTranslation } from "react-i18next";

function About() {
  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const loadingTranslations = useFetchTranslations("About");

  if (loadingTranslations) return <LoadingSpinner />;

  return (
    <Container className="about-page my-5">
      <AboutContent />
      <LogoGrid />
      <ContactButton />
    </Container>
  );
}

function AboutContent() {
  const { t } = useTranslation();
  return (
    <Row>
      <Col>
        <h1>{t("aboutTitle")}</h1>
        <p>{t("welcomeText1")}</p>
        <p>{t("welcomeText2")}</p>
        <h2>{t("missionTitle")}</h2>
        <p>{t("missionText")}</p>
        <h2>{t("whyChooseUsTitle")}</h2>
        <ul>
          <li>{t("benefit1")}</li>
          <li>{t("benefit2")}</li>
          <li>{t("benefit3")}</li>
          <li>{t("benefit4")}</li>
        </ul>
      </Col>
    </Row>
  );
}

function ContactButton() {
  const { t } = useTranslation();
  return (
    <Row className="my-4 text-center">
      <Col>
        <Button className="btn btn-primary-custom" as={Link} to="/contact">
          {t("contactUsButton")}
        </Button>
      </Col>
    </Row>
  );
}

export default About;
