import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { loadTranslations } from "../i18n";

const useFetchTranslations = (namespace) => {
  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchTranslations() {
      setLoading(true);
      const translations = await loadTranslations(i18n.language, namespace);
      i18n.addResources(i18n.language, "translation", translations);
      setLoading(false);
    }

    fetchTranslations();
    i18n.on("languageChanged", fetchTranslations);

    return () => {
      i18n.off("languageChanged", fetchTranslations);
    };
  }, [i18n, namespace]);

  return loading;
};

export default useFetchTranslations;
