import React from "react";
import { Container } from "react-bootstrap";
import WelcomeSection from "../components/WelcomeSection";
import AboutSection from "../components/AboutSection";
import ServicesSection from "../components/ServicesSection";
import Testimonials from "../components/Testimonials";
import RecentProjectsSection from "../components/RecentProjectsSection";
import LatestPostsSection from "../components/LatestPostsSection";
import CtaSection from "../components/CtaSection";
import useFetchVisibilityRules from "../hooks/useFetchVisibilityRules";
import LoadingSpinner from "../components/LoadingSpinner";

function Home() {
  const { visibilityRules, loading } = useFetchVisibilityRules();

  if (loading) return <LoadingSpinner />;

  return (
    <div className="home-container py-5">
      <Container>
        {visibilityRules.WelcomeSection && <WelcomeSection />}
        {visibilityRules.AboutSection && <AboutSection />}
        {visibilityRules.ServicesSection && <ServicesSection />}
        {visibilityRules.Testimonials && <Testimonials />}
        {visibilityRules.RecentProjectsSection && <RecentProjectsSection />}
        {visibilityRules.LatestPostsSection && <LatestPostsSection />}
        {visibilityRules.CtaSection && <CtaSection />}
      </Container>
    </div>
  );
}

export default Home;
