import React from 'react';
import Blog from '../components/Blog';

function BlogPage() {
  return (
    <div>
      <Blog />
    </div>
  );
}

export default BlogPage;