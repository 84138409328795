import React from "react";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "../components/LoadingSpinner";
import useFetchBlogPost from "../hooks/useFetchBlogPost";

function BlogDetail() {
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const { i18n, t } = useTranslation();
  const { post, loading } = useFetchBlogPost(id, i18n.language);

  if (loading) return <LoadingSpinner />;

  return (
    <Container className="mt-5">
      <h2>{post?.title}</h2>
      {post?.image && (
        <img src={post.image} alt={post.title} className="img-fluid mb-4" />
      )}
      <p>{post?.content}</p>
    </Container>
  );
}

export default BlogDetail;
