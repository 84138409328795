import { useState } from 'react';
import api from '../api';

function useSubmitForm(url, initialData) {
  const [formData, setFormData] = useState(initialData);
  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    api.post(url, formData)
      .then(() => {
        setSubmitted(true);
        setFormData(initialData); // Reset form data after submission
      })
      .catch((error) => {
        console.error('Error sending contact form:', error);
      });
  };

  return { formData, submitted, handleChange, handleSubmit };
}

export default useSubmitForm;
